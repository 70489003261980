<template>
    <div>
        <h2>{{ $t("compta.acte_type_article") }}</h2>
        <div class="box">
            <CustomTable
                id_table="actes_type_articles"
                :items="filtredActesTypeArticles"
                :busy.sync="table_busy"
                primaryKey="actestype_id"
                :hide_if_empty="true"
                :rawColumns="rawColumnNames"
            />

            <b-modal ref="modal" hide-footer>
                <template v-slot:modal-title>
                    <template v-if="ajouter">
                        {{ $t("compta.ajouter_lien_acte_type_article") }}
                    </template>

                    <template v-if="modifier">
                        {{ $t("compta.modifier_lien_acte_type_article") }}
                    </template>
                </template>
                
                <ActesTypeArticlesAjout 
                    :add-or-edit-ready.sync="addOrEditReady"
                    ref="actesTypeArticlesAjout"
                    :actestype_id="actestype_id"
                    :accountingplan_id="accountingplan_id"
                    :actes_type_articles="actes_type_articles"
                />
                <div class="text-center">
                <b-button v-if="ajouter && addOrEditReady"  pill variant="primary" class="mt-3"  @click.prevent="checkForm"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("global.ajouter") }}</b-button>
                <b-button v-if="modifier && addOrEditReady"  pill variant="primary" class="mt-3"  @click.prevent="checkForm"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("global.modifier") }}</b-button>
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script type="text/javascript">
    import Navigation from "@/mixins/Navigation.js"
    import Accounting from "@/mixins/Accounting.js"
    import ActeType from "@/mixins/ActeType.js"
    import Actes from "@/mixins/Actes.js"
    import Shutter from '@/mixins/Shutter.js'
    import { EventBus } from 'EventBus'

    export default {
        name: "ActeTypeArticle",
        mixins: [Navigation, Accounting, ActeType, Actes, Shutter],
        props: ['accountingplan_id', 'code_onboarding'],
        data () {
            return {
                addOrEditReady: false,
                processing: false,
                ajouter: false,
                modifier: false,
                actestype_id: null,
                actes_type_articles: null,
                table_busy: true,
                rawColumnNames: ['articles_label', 'articles_code'],
                events_tab: {
                    'TableAction::goToAddActeTypeArticle': () => {
                        this.addOrEdit()
                    },
                    'TableAction::goToEditActeTypeArticle': (params) => {
                        this.addOrEdit(params.actestype_id)
                    }
                }
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.loadActesArticlesListe()
            },
            async loadActesArticlesListe() {
                this.table_busy = true

                let actes_type_articles = []
                const acte_groupes = await this.getActesGroupes()
                const acte_types = (await this.getActesTypes()).filter(at => at.active === true)
                const articles = await this.loadArticles(this.accountingplan_id)

                if(acte_groupes.length > 0 && acte_types.length > 0) {
                    acte_types.forEach(type => {
						const temp_group = this.deppCloneObj(acte_groupes).filter(groupe => groupe.actesgroupe_code == type.actestype_codegroupe)[0]
						const temp_type = this.deppCloneObj(type)
						const temp = { ...temp_group, ...temp_type }
						actes_type_articles.push(temp)
                    })
                }

                this.actes_type_articles = this.format_actes_type_articles(articles, actes_type_articles)
                this.table_busy = false
            },
            async addOrEdit(actestype_id) {
                this.processing = false
                this.addOrEditReady = false
                if(actestype_id) {
                    this.ajouter = false
                    this.modifier = true
                    this.actestype_id = actestype_id
                } else {
                    this.ajouter = true
                    this.modifier = false
                    this.actestype_id = null
                }

                this.$refs.modal.show()
            },
            async checkForm() {
                if(!this.processing) {
                    this.processing = true
                    let result = await this.$refs.actesTypeArticlesAjout.checkForm()
                    if(result) {
                        this.$refs.modal.hide()
                        this.loadActesArticlesListe()
                    } else {
                        this.processing = false
                    }
                }

                if(this.code_onboarding) {
                    this.ok()
                }
            }
        },
        computed: {
            filtredActesTypeArticles: function() {
                return this.actes_type_articles
            }
        },
        components: {
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
            HeaderTab: () => import('@/components/HeaderTab') ,
            ActesTypeArticlesAjout: () => import('@/components/Accounting/ActesTypeArticlesAjout'),
        }
    }
</script>
